import { version } from '../../package.json';
export const baseUrl: string = 'https://uat.harperjames.co.uk';

export const environment = {
  production: true,
  apiUrl: `${baseUrl}/api`,
  appVersion: `${version}-uat`,
  appInsightsConnectionString:
    'InstrumentationKey=6c130d0c-c008-467f-b57a-e6aaec512ac2;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/',

  config: {
    auth: {
      clientId: 'e7765baf-00d5-4e0c-aeba-8f74f03937ce',
      authority: 'https://login.microsoftonline.com/ff9c468c-4463-4262-a31d-5401f37e4b9d',
      validateAuthority: true,
      redirectUri: '/',
      postLogoutRedirectUri: '/logout',
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
    resources: {
      demoApi: {
        resourceUri: 'https://graph.microsoft.com/v1.0/me',
        resourceScope: 'api://e7765baf-00d5-4e0c-aeba-8f74f03937ce/ReadUser', // here use the client id of the Web API you registered
      },
    },
    scopes: {
      loginRequest: ['user.read'],
    },
  },
};
